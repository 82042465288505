const state = {
  isAuthenticated: false,
  isRegistered: false,
  status: String
}

const getters = {
  isSurfConextAuthenticated: state => {
    // The user just passed SurfConext
    return state.isAuthenticated
  },
  isLoggedIn: state => {
    /* eslint-disable */
    // console.log(state.isAuthenticated)
    // console.log(state.isRegistered)
    /* eslint-enable */
    return state.isAuthenticated && state.isRegistered
  }
}

const actions = {
  GET_AUTH_STATUS ({ commit }) {
    return this._vm.$vvtpApi
      .get('/auth/status/', {})
      .then(response => {
        const isAuthenticated = (response.data.data.is_authenticated === 'True')
        const isRegistered = (response.data.data.is_registered === 'True')
        commit('SET_AUTH_STATUS', { isAuthenticated, isRegistered })
        return Promise.resolve(response)
      })
      .catch(error => {
        commit('LOGIN_ERROR')
        return Promise.reject(error)
      })
  }
}

const mutations = {
  SET_AUTH_STATUS (state, { isAuthenticated, isRegistered }) {
    state.isAuthenticated = isAuthenticated
    state.isRegistered = isRegistered
    // eslint-disable-next-line
    console.log(state.isRegistered)
    state.status = 'success'
  },
  LOGIN_ERROR (state, error) {
    state.status = 'error'
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
